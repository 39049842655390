import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
// material
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LockIcon from "@mui/icons-material/Lock";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { htmlDecode } from "src/utils/convertHtml";

import {
  live_events_list_api,
  _list_of_member_for_chat_against_event,
} from "src/DAL/LiveEvents/LiveEvents";
import { s3baseUrl } from "src/config/config";
import { RecordNotFound } from "src/components/_dashboard/programmes";
import CustomMUICard from "src/components/CustomMUICard";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Ensure this CSS is imported

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

// ----------------------------------------------------------------------

export default function LiveEvents() {
  const { enqueueSnackbar } = useSnackbar();
  const params = useParams();
  const navigate = useNavigate();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventsList] = useState({});
  const { contentSettingData } = useContentSetting();

  const handleClick = (object) => {
    delete object.card_button;
    if (!object.locked) {
      navigate(`/live-events/${object._id}`);
    } else if (
      object.lock_configration &&
      object.lock_configration.lock_event_description !== ""
    ) {
      navigate(`/live-events-locked/${object._id}`, { state: object });
    }
  };

  const getLiveEventsList = async () => {
    const result = await live_events_list_api();
    if (result.code === 200) {
      let CARD_OPTIONS = [];
      result.member_dynamite_event.map((dynamite_event) => {
        CARD_OPTIONS.push({
          ...dynamite_event,
          card_title: dynamite_event.title,
          card_short_description: dynamite_event.short_description,
          card_image: s3baseUrl + dynamite_event.images.thumbnail_2,
          card_classes: "col-lg-4 col-md-6 col-sm-12 mt-4",
          card_button: dynamite_event.locked === true && {
            button_title: (
              <>
                {/* <LockIcon />
                {` Unlock Access`} */}

                {dynamite_event.lock_configration ? (
                  dynamite_event.lock_configration.lock_event_logo ? (
                    <img
                      src={
                        s3baseUrl +
                        dynamite_event.lock_configration.lock_event_logo
                      }
                      width={20}
                      className="me-1"
                    />
                  ) : (
                    <LockIcon className="lock-icon-color" />
                  )
                ) : (
                  <>
                    <LockIcon className="lock-icon-color" />
                  </>
                )}
                {dynamite_event.lock_configration &&
                dynamite_event.lock_configration.lock_event_button_text
                  ? dynamite_event.lock_configration.lock_event_button_text
                  : "Unlock Access"}
              </>
            ),
            handleClick: handleClick,
          },
        });
      });
      setEventsList(CARD_OPTIONS);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getLiveEventsList();
  }, []);

  if (isLoading) {
    return (
      <div className="container mt-2">
        <div className="row">
          {Array.from({ length: 6 }).map((_, index) => (
            <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
              <div className="card mt-1 pods-cards-shadow cursor h-100">
                <Skeleton
                  height={200}
                  className="card-img-top pods-image"
                  baseColor="#dddde8"
                  highlightColor="#ffffff"
                />
                <div className="card-body">
                  <Skeleton
                    height={30}
                    width="80%"
                    style={{ marginBottom: 10 }}
                    className="h2-heading"
                    baseColor="#dddde8"
                    highlightColor="#9F00FF"
                  />
                  <Skeleton
                    height={20}
                    width="90%"
                    className="programme-card-desc mb-3"
                    baseColor="#dddde8"
                    highlightColor="#ffffff"
                  />
                  <Skeleton
                    height={20}
                    width="60%"
                    baseColor="#dddde8"
                    highlightColor="#ffffff"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  return (
    <>
      <div className="container">
        <div className="row">
          {/* <h2>Portals</h2> */}
          {contentSettingData && contentSettingData.portal_description ? (
            <div className="dashboard_description">
              {htmlDecode(contentSettingData.portal_description)}
            </div>
          ) : (
            <h2>Portals</h2>
          )}
        </div>
        {eventsList.length > 0 ? (
          <CustomMUICard data={eventsList} handleClick={handleClick} />
        ) : (
          <RecordNotFound title="Portals Not Found" />
        )}
      </div>
    </>
  );
}
