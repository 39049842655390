import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import TicketList from "src/components/_dashboard/SupportTickets/TicketList";
import AddSupportTicket from "./AddSupportTicket";
import SupportTicketFilter from "./SupportTicketFilter";
import roundFilterList from "@iconify/icons-ic/round-filter-list";
import { tickestsListAPI } from "src/DAL/SupportTicket/SupportTicket";
import { useSnackbar } from "notistack";
import {
  Card,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

function SupportTicket() {
  const navigate = useNavigate();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [drawerState, setDrawerState] = useState(false);
  const [editDrawerState, setEditDrawerState] = useState(false);
  const [tickestData, setTickestData] = useState([]);
  const [filterBy, setFilterBy] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedValue, setSelectedValue] = React.useState("all");
  const { contentSettingData } = useContentSetting();
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const getTickestsListing = async (filterBy) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("filter_by", filterBy);
    const result = await tickestsListAPI(formData);
    if (result.code === 200) {
      setTickestData(result.support_ticket);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const myfunction = (value) => {
    navigate(`/support-ticket/${value}`);
  };

  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = (closeType) => {
    if (closeType === "filter") {
      console.log(closeType, "filter applied");
    } else {
      setSelectedValue("all");
    }
    setEditDrawerState(false);
  };

  useEffect(() => {
    getTickestsListing(filterBy);
  }, []);

  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-sm-12 col-md-6">
          {contentSettingData &&
          contentSettingData.support_ticket_page_title ? (
            <div
              className="dashboard_description"
              dangerouslySetInnerHTML={{
                __html: contentSettingData.support_ticket_page_title,
              }}
            ></div>
          ) : (
            <h2>Support Tickets</h2>
          )}
        </div>
        <div className="col-sm-12 col-md-6 text-end">
          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={handleOpenDrawer}
          >
            New Ticket
          </button>
          <button
            className="small-contained-button me-2 mt-1 mb-4"
            onClick={handleOpenEditDrawer}
          >
            Filters &nbsp;&nbsp; <Icon icon={roundFilterList} />
          </button>
        </div>
        {isLoading ? (
          <div>
            <Card style={{ overflowX: "auto" }}>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {/* Skeleton for Table Header */}
                      <TableCell>
                        <Skeleton width={150} height={20} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={50} height={20} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={200} height={20} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={100} height={20} />
                      </TableCell>
                      <TableCell>
                        <Skeleton width={100} height={20} />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* Skeleton Loading for Rows */}
                    {Array.from({ length: 6 }).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton width={50} height={20} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={50} height={20} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={200} height={20} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={100} height={20} />
                        </TableCell>
                        <TableCell>
                          <Skeleton width={100} height={20} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  margin: "20px",
                  justifyContent: "end",
                  display: "flex",
                }}
              >
                <Skeleton width={200} height={20} />
              </div>
            </Card>
          </div>
        ) : (
          <TicketList
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            data={tickestData}
            onClick={myfunction}
            getTickestsListing={getTickestsListing}
          />
        )}
      </div>
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <SupportTicketFilter
            value={selectedValue}
            handleChange={handleChange}
            dataList={getTickestsListing}
            setSelectedValue={setSelectedValue}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
      <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="New Ticket"
        componentToPassDown={
          <AddSupportTicket
            dataList={getTickestsListing}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
    </div>
  );
}

export default SupportTicket;
