import React from "react";
import Skeleton from "react-loading-skeleton";

export default function CalenderSkeleton() {
  return (
    <div className="col-lg-12 col-md-6 col-sm-12 mt-4 h-100vh mt-2">
      <Skeleton
        baseColor="#fff"
        highlightColor="#ddd"
        width="100%"
        height={500}
      />
    </div>
  );
}
