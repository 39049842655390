import {
  CircularProgress,
  IconButton,
  TextareaAutosize,
  Tooltip,
} from "@mui/material";
import { useRef, useState } from "react";
import moment from "moment";
import no_data_found from "src/assets/images/no_data_found.png";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import CustomConfirmation from "src/components/CustomConfirmation";
import PageDescription from "src/components/GeneralComponents/PageDescription";
import ActionBarComponentProps from "./calender";
import { Icon } from "@iconify/react";
import { ReactNormailVideoPlayer } from "src/components";
import CustomPopover from "src/components/CustomPopover";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addAffirmationDailyApi,
  deleteAffirmationDailyApi,
  listAffirmationDailyApi,
  listAllActiveAffirmations,
  listRecent,
  updateAffirmationDailyApi,
} from "src/DAL/AffirmationDaily/Affirmation";
import { s3baseUrl } from "src/config/config";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  loadingform: {
    marginLeft: "50%",
    // margin: "auto",
    marginTop: "5%",
    marginBottom: "5%",
  },
}));

export default function DailyAffirmation() {
  const { contentSettingData, handleGeneralSettingData } = useContentSetting();
  const navigate = useNavigate();
  const { state } = useLocation();
  const location = useLocation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [note, setNote] = useState("");
  const [addNewArray, setaddNewArray] = useState([
    { option: "", audio: "" },
    { option: "", audio: "" },
  ]);
  const [recent, setRecent] = useState([]);
  const [diaryDate, setDiaryDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [firstDate, setFirstDate] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [objectDataAvailable, setObjectDataAvailable] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteValue, setDeleteValue] = useState("");
  const [editValue, setEditValue] = useState("");
  const [activeAffirmations, setActiveAffirmations] = useState([]);
  const [audioPlaying, setAudioPlaying] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const toggleAudio = (audioSrc) => {
    if (audioPlaying === audioSrc) {
      // If the clicked audio is already playing, pause it
      setAudioPlaying(null);
    } else {
      // If a different audio is clicked, play it
      setSelectedAudio(audioSrc);
      setAudioPlaying(audioSrc);
    }
  };

  const handleAdd = (index) => {
    const addedElement = [...addNewArray];
    addedElement.splice(index + 1, 0, { option: "", audio: "" });
    setaddNewArray(addedElement);
  };

  const handleDelete = (i) => {
    const delValue = [...addNewArray];
    delValue.splice(i, 1);
    setaddNewArray(delValue);
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...addNewArray];
    list[index][name] = value;
    setaddNewArray(list);
  };
  const handleChangeDate = (value) => {
    console.log(value.$d);
    const startDate = new Date(value.$d);
    const year = startDate.getFullYear();
    const month = String(startDate.getMonth() + 1).padStart(2, "0");
    const day = String(startDate.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setDiaryDate(formattedDate);
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteValue(value);
  };
  const handleDeleteDiary = async (value) => {
    const transformedArray = deleteValue.affirmations.map((item) => ({
      affirmation_id: item._id,
      date: item.date,
    }));
    let postData = {
      date: deleteValue.date,
    };
    const result = await deleteAffirmationDailyApi(postData);
    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });

      if (diaryDate === deleteValue.date) {
        setEditValue("");
        setObjectDataAvailable(false);
        setNote("");
        setaddNewArray([
          { option: "", audio: "" },
          { option: "", audio: "" },
        ]);
      }
      setRecent((prevRecent) =>
        prevRecent.filter((entry) => entry.date !== deleteValue.date)
      );
      handleGeneralSettingData(result?.members_coins);
      setOpenDelete(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setOpenDelete(false);
    }
  };
  const handleNavigate = () => {
    navigate(`/daily-affirmation/past-activities`);
  };
  const handleEdit = (value) => {
    console.log(value, "edit valueeeeee");
    setEditValue(value);
    // setNote(value.affirmation_text);
    setDiaryDate(value?.affirmationDate);
    setObjectDataAvailable(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const missingAudio = addNewArray.some((item) => item.audio === "");

    if (missingAudio) {
      // Display error message
      enqueueSnackbar("Audio is required", { variant: "error" });
      return;
    }
    setIsSubmit(true);
    const affirmationArray = addNewArray.map((item) => ({
      _id: item.audio._id,
      text: item.option,
    }));
    let postData = {
      date: diaryDate,
      affirmations: affirmationArray,
    };
    console.log(postData, "postData");
    // setaddNewArray([{ option: "" }]);
    const result = await addAffirmationDailyApi(postData);
    if (result.code == 200) {
      handleGeneralSettingData(result?.members_coins);
      getRecent();

      // setEditValue(result.affirmation);
      setObjectDataAvailable(true);
      enqueueSnackbar(result.message, { variant: "success" });
      setIsSubmit(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmit(false);
    }
  };
  const handleDrop = (e, index) => {
    e.preventDefault();
    const data = JSON.parse(e.dataTransfer.getData("text/plain"));
    const updatedArray = [...addNewArray];
    updatedArray[index] = { ...updatedArray[index], audio: data };

    setaddNewArray(updatedArray);
  };

  const removeAudio = (index) => {
    const newArray = [...addNewArray];
    newArray[index].audio = "";
    setaddNewArray(newArray);
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    const missingAudio = addNewArray.some((item) => item.audio === "");

    if (missingAudio) {
      // Display error message
      enqueueSnackbar("Audio is required", { variant: "error" });
      return;
    }
    setIsSubmit(true);
    const affirmationArray = addNewArray.map((item) => ({
      affirmation_id: item.audio._id,
      text: item.option,
    }));
    let postData = {
      affirmations: affirmationArray,
      date: editValue.date ? editValue.date : diaryDate,
    };
    const result = await updateAffirmationDailyApi(postData);

    if (result.code == 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getRecent();
      setIsSubmit(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsSubmit(false);
    }
  };
  const getDailyGratitude = async () => {
    setIsFormLoading(true);
    let postData = {
      date: diaryDate,
    };
    const result = await listAffirmationDailyApi(postData);
    console.log(result, "resultresultresultresultresultresult");
    let allCoins =
      result.member.affirmation_coins +
      result.member.challenges_coins +
      result.member.goal_coins_count +
      result.member.gratitude_coins +
      result.member.meditation_coins +
      result.member.reward_affirmation_coins +
      result.member.reward_attitude_coins +
      result.member.reward_bonus_coins +
      result.member.reward_gratitude_coins +
      result.member.reward_meditation_coins;
    if (result.code === 200) {
      setFirstDate(result?.affirmation.affirmation_start_date);
      handleGeneralSettingData(allCoins);
      if (result?.affirmation.affirmations.length > 0) {
        const alNotes = result?.affirmation.affirmations.map((item) => ({
          option: item.text,
          audio: item,
        }));
        setObjectDataAvailable(true);
        console.log(alNotes, "alNotes");
        setaddNewArray(alNotes);
      } else {
        setObjectDataAvailable(false);
        setaddNewArray([
          { option: "", audio: "" },
          { option: "", audio: "" },
        ]);
        setEditValue("");
      }

      setIsLoading(false);
      setIsFormLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
      setIsFormLoading(false);
    }
  };
  const handleDragStart = (e, data) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(data));
  };
  const preventTextAreaDrop = (e) => {
    e.preventDefault();
  };
  const handleDragOverInvalid = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "none"; // Indicate that drop is not allowed
  };

  // Restore cursor style when leaving invalid drop target
  const handleDragLeaveInvalid = (e) => {
    e.preventDefault();
    e.dataTransfer.dropEffect = "move"; // Restore to move effect
  };
  const getActiveAffirmations = async () => {
    const result = await listAllActiveAffirmations();
    if (result.code === 200) {
      setActiveAffirmations(result.affirmations);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const getRecent = async () => {
    const result = await listRecent();
    if (result.code === 200) {
      const recent_gratitudes = result?.member_affirmations?.map(
        (gratitude, index) => {
          return {
            ...gratitude,
            affirmation_text: gratitude.affirmations,
            affirmationDate: moment(gratitude.date).format("YYYY-MM-DD"),
          };
        }
      );
      setRecent(recent_gratitudes);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getActiveAffirmations();
    getRecent();
    if (location.state) {
      console.log(state, "From state");
      setEditValue(state);
      setDiaryDate(state?.date);
      // setNote(state?.affirmation_text);
      setObjectDataAvailable(true);
      location.state = undefined;
    } else {
      getDailyGratitude();
    }
  }, [diaryDate, state]);

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
  ];
  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Are you sure you want to delete affirmation ?"}
        handleAgree={handleDeleteDiary}
      />

      <section className="container dynamite-diary">
        <div className="row">
          <div className="col-lg-6">
            <PageDescription
              parameter="daily_affirmation_heading"
              else_title="Daily Affirmation"
            />
          </div>
          <div className="col-lg-6 text-end"></div>
        </div>
        <section className="container top-section-video mt-3 mb-lg-3">
          <div className="row pt-70" id="assessment-container">
            <div className="col-lg-7 col-md-6 mb-2">
              <ReactNormailVideoPlayer
                url={contentSettingData?.daily_affirmation_url}
              />
            </div>
            <div className="col-lg-5 col-md-6 text-md-start text-center">
              <div
                className="d-flex calender w-100 full-open-calender lg-mt-4 mb-2"
                style={{
                  height: "97%",
                }}
              >
                <ActionBarComponentProps
                  diaryDate={diaryDate}
                  setDiaryDate={setDiaryDate}
                  handleChangeDate={handleChangeDate}
                  firstDiaryDate={firstDate}
                />
              </div>
            </div>
          </div>
        </section>
        <div className="container affirmations mb-3">
          <div className="row  mini-cards" style={{ marginLeft: "2px" }}>
            {activeAffirmations.length > 0 ? (
              <>
                {activeAffirmations?.map((data, index) => {
                  return (
                    <div
                      className="col-lg-4 col-md-4 col-sm-12 mb-2"
                      key={index}
                      draggable
                      onDragStart={(e) => handleDragStart(e, data)}
                    >
                      <div className="audio-card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-2 d-flex align-items-center">
                              <IconButton
                                className="audio-icon"
                                onClick={() => toggleAudio(data.audio)}
                              >
                                <Icon
                                  width={30}
                                  height={30}
                                  icon={"bi:volume-up-fill"}
                                />
                              </IconButton>
                            </div>
                            <div className="col-10 audio-text">
                              {data.title}
                            </div>
                            <div className="col-12">
                              {audioPlaying === data.audio && (
                                <audio
                                  controlsList="nodownload"
                                  className="w-100"
                                  src={s3baseUrl + data.audio}
                                  controls
                                  autoPlay
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <div className="no-access-string">
                  <img className="mx-auto" src={no_data_found} />
                  <h5 className="mt-3 heading portal-color">
                    Affirmation Audios Not Found
                  </h5>
                </div>
              </>
            )}
          </div>
        </div>

        <div className="container">
          {isFormLoading ? (
            <>
              <CircularProgress
                className={classes.loadingform}
                color="primary"
              />
              ;
            </>
          ) : (
            <>
              <div className="row">
                <div>
                  <form
                    onSubmit={
                      objectDataAvailable == true ? handleUpdate : handleSubmit
                    }
                  >
                    {addNewArray?.map((data, index) => {
                      return (
                        <div
                          className="d-flex position-relative mt-3"
                          key={index}
                        >
                          <div className="field-container drag-drop-container">
                            <div className="mt-2 d-flex mt-2 row">
                              <div
                                className="col-lg-2 col-sm-12 col-md-12 drag-drop-audio"
                                onDragOver={(e) => e.preventDefault()}
                                onDrop={(e) => handleDrop(e, index)}
                              >
                                {data.audio.title ? (
                                  <>
                                    <Icon
                                      width={30}
                                      height={30}
                                      icon={"bi:volume-up-fill"}
                                    />
                                    <IconButton
                                      className="audio-cross-icon"
                                      onClick={() => removeAudio(index)}
                                    >
                                      <Icon
                                        width={11}
                                        height={11}
                                        icon={"icomoon-free:cross"}
                                      />
                                    </IconButton>
                                    <p className="drop-audio-title">
                                      {data.audio.title}
                                    </p>
                                  </>
                                ) : (
                                  <p className="drag-drop-text">
                                    Drop Audio Here{" "}
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "15px",
                                        position: "relative",
                                        top: "1px",
                                      }}
                                    >
                                      +
                                    </span>
                                  </p>
                                )}
                              </div>
                              <span className="col-lg-10 col-sm-12 col-md-12">
                                <TextareaAutosize
                                  aria-label="empty textarea"
                                  placeholder={`${index + 1}.`}
                                  className="text-color textarea-autosize textAreaAutoSize"
                                  sx={{ color: "white" }}
                                  minRows={1}
                                  required
                                  name="option"
                                  onDrop={preventTextAreaDrop} // Prevent drop event
                                  onDragOver={handleDragOverInvalid} // Indicate invalid drop target
                                  onDragEnter={handleDragOverInvalid} // Indicate invalid drop target
                                  onDragLeave={handleDragLeaveInvalid}
                                  value={data.option}
                                  onChange={(e) => handleChange(e, index)}
                                />
                              </span>
                            </div>
                          </div>

                          <span className="cross-icon">
                            <Tooltip title="Add">
                              <AddCircleOutlineIcon
                                onClick={() => handleAdd(index)}
                                className="diary-icon-add"
                              />
                            </Tooltip>
                            {addNewArray.length > 1 ? (
                              <Tooltip title="Remove">
                                <RemoveCircleOutlineIcon
                                  onClick={() => handleDelete(index)}
                                  className="diary-icon-remove"
                                />
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      );
                    })}

                    <div className="row mt-2">
                      <div className="col-12">
                        {objectDataAvailable ? (
                          <button className="small-contained-button float-end mt-1 mb-4">
                            {isSubmit ? "Updating..." : "Update"}
                          </button>
                        ) : (
                          <button className="small-contained-button float-end mt-1 mb-4">
                            {isSubmit ? "Saving.." : "Save"}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}

          {recent?.length > 0 ? (
            <>
              <div className="row">
                <div className="recent col-lg-12 d-flex me-2 pe-3">
                  <h3>Recent Activity</h3>
                </div>
                {recent?.map((value, index) => {
                  return (
                    <div
                      className="col-lg-6 mb-3"
                      // style={{ cursor: "pointer" }}
                      key={index}
                    >
                      <div className="mini-cards d-flex">
                        <div className="w-100 ms-2 me-2 gratitude-text">
                          <div className="diary-font-weight d-flex">
                            <h4 className="normal-font">
                              {value?.affirmationDate
                                ? value?.affirmationDate
                                : ""}
                            </h4>
                            <div className="d-flex tools">
                              <CustomPopover menu={MENU_OPTIONS} data={value} />
                            </div>
                          </div>

                          <div className="d-flex justify-content-between ml-3 mt-2 diary-first-heading">
                            <div>
                              <h4>
                                {" "}
                                {value?.affirmation_text
                                  ? value.affirmation_text.map(
                                      (item, index) => (
                                        <div
                                          key={index}
                                          className="normal-font"
                                        >
                                          {index + 1}. {item.text}
                                        </div>
                                      )
                                    )
                                  : ""}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row mt-3">
                <div className="col-12">
                  <button
                    className="small-contained-button float-end mt-1 mb-4"
                    onClick={handleNavigate}
                  >
                    Past Activities
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </section>
    </>
  );
}
