import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { PodsListing } from "src/DAL/Pods/Pods";
import PodsCards from "../../components/Pods/Pods";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css"; // Ensure this CSS is imported

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
function Pods() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [podsData, setPodsData] = useState([]);
  const getPodsListing = async () => {
    const result = await PodsListing();
    if (result.code === 200) {
      setPodsData(result.room);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  const handleNavigate = (podsSlug) => {
    if (podsSlug?.is_locked) {
      // enqueueSnackbar("You Don't have Access to this Pod", {
      //   variant: "error",
      // });
    } else {
      navigate(`/meetings/meetings-detail/${podsSlug.room_slug}`);
    }
  };

  useEffect(() => {
    getPodsListing();
  }, []);
  if (isLoading) {
    return (
      <div className="container mt-2">
        <div className="row">
          {Array.from({ length: 6 }).map((_, index) => (
            <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
              <div className="card mt-1 pods-cards-shadow cursor h-100">
                <Skeleton
                  height={200}
                  className="card-img-top pods-image"
                  baseColor="#dddde8"
                  highlightColor="#ffffff"
                />
                <div className="card-body">
                  <Skeleton
                    height={30}
                    width="80%"
                    style={{ marginBottom: 10 }}
                    className="h2-heading"
                    baseColor="#dddde8"
                    highlightColor="#9F00FF"
                  />
                  <Skeleton
                    height={20}
                    width="90%"
                    className="programme-card-desc mb-3"
                    baseColor="#dddde8"
                    highlightColor="#ffffff"
                  />
                  <Skeleton
                    height={20}
                    width="60%"
                    baseColor="#dddde8"
                    highlightColor="#ffffff"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <PodsCards
      handleNavigate={handleNavigate}
      data={podsData}
      notFoundtitle="Meetings"
    />
  );
}

export default Pods;
