import React, { useState, useEffect, useRef } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Tooltip from "@mui/material/Tooltip";
import "@fullcalendar/daygrid/main.css";
import moment from "moment";
import AddEvent from "./AddEvent";
import EventDetail from "./EventDetail";
import {
  delete_event_api,
  new_event_list_api,
} from "src/DAL/Calender/Calender";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CustomDrawer from "src/components/FormsDrewer/CustomDrawer";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { TIME_ZONE_CONVERSION } from "src/utils/constants";
import WeekCalendar from "./WeekCalendar";
import DayCalendar from "./DayCalendar";
import FullPagePopup from "src/components/FormsDialog/FullPagePopup";
import DetailPopUpModel from "src/components/Calender/DetailPopUpModel";
import EventDetailNew from "src/components/FormsDialog/EventDetailNew";
import PerformActionOn from "src/components/FormsDialog/PerformActionOn";
import EditEvent from "./EditEventNew";
import { useSnackbar } from "notistack";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CalenderSkeleton from "src/components/allSkeleton/CalenderSkeleton";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const get_view_dates = (calendarValue) => {
  let tool = calendarValue;
  // if(calendarValue=="month")

  const startOfMonth = moment().startOf(tool);
  // console.log(startOfMonth, "asfjlks");
  const daysToSubtract = startOfMonth.day() - 0; // 0 represents Sunday
  const previousSunday = startOfMonth.subtract(daysToSubtract, "days");
  let previousSundayDate = previousSunday.toDate();
  let add_days = 42;
  if (tool == "week") {
    add_days = 6;
  } else if (tool == "day") {
    add_days = 0;
  }
  let nextSaturday = startOfMonth.add(add_days, "days");
  let nextSaturdayDate = nextSaturday.toDate();
  if (tool == "day") {
    previousSundayDate = new Date();
    nextSaturdayDate = new Date();
  }

  return {
    start_date: previousSundayDate,
    end_date: nextSaturdayDate,
  };
};
function Calender(props) {
  const { userInfo, adminTimeZone, setUserInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [events, setEvents] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const [eventDetailData, setEventDetailData] = useState([]);

  const [editDrawerState, setEditDrawerState] = useState(false);
  const [drawerState, setDrawerState] = useState(false);
  const classes = useStyles();
  const { contentSettingData } = useContentSetting();
  const [selectedTool, setSelectedTool] = useState(
    "month"
    // userInfo.default_calendar_value
  );
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [openDelete, setOpenDelete] = useState(false);
  const [popupState, setPopupState] = useState(false);
  const calendarRef = useRef(null);
  const get_dates = (tool) => {
    if (tool == "month") {
      return get_view_dates(tool);
    } else {
      return {
        start_date: moment().startOf(tool).toDate(),
        end_date: moment().endOf(tool).toDate(),
      };
    }
  };
  const handleOpenDrawer = () => {
    setDrawerState(true);
  };
  const handleOpenEditDrawer = () => {
    setEditDrawerState(true);
  };

  const handleCloseDrawer = () => {
    setDrawerState(false);
  };
  const handleCloseEditDrawer = () => {
    setEditDrawerState(false);
  };

  // old drawer code
  // const eventDetail = (event) => {
  //   setEventDetailData(event);
  //   setEditDrawerState(true);
  // };
  const eventDetail = (event) => {
    setEventDetailData(event);
    setPopupState(true);
  };
  const handleUpdate = () => {
    handleOpenEditDrawer();
  };

  const handleAgreeDelete = () => {
    setPopupState(false);
    setOpenDelete(true);
  };

  //Deleting event
  const handleDelete = async (value) => {
    setOpenDelete(false);
    if (selectedTool !== "month") {
      setIsLoading(true);
    }
    let postData = {
      event_slug: eventDetailData.event_slug,
      iteration_id: eventDetailData._id,
      update_type: value,
    };
    // console.log(postData, "flksdfjkasdl");
    const result = await delete_event_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      getEventListing();
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const renderEventContent = (eventInfo) => {
    return (
      <Tooltip title={eventInfo.event.title}>
        <div
          className="d-flex"
          onClick={() => eventDetail(eventInfo.event._def.extendedProps)}
        >
          <div
            className="event-title-dot"
            style={{
              backgroundColor: eventInfo.event._def.extendedProps.event_color,
            }}
          ></div>
          <div className="calendar-event-title"></div>
          <div className="calendar-event-title">
            <span className="start_time_for_popup">
              {console.log(eventInfo.event.title, "sdlkskjdfads")}
              {eventInfo.event._def.extendedProps.start_time_for_popup}
            </span>
            &nbsp;&nbsp;
            {eventInfo.event.title.length > 15
              ? `${eventInfo.event.title.substring(0, 15)}...`
              : eventInfo.event.title}
          </div>
        </div>
      </Tooltip>
    );
  };
  const get_converted_date = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "YYYY-MM-DD",
      adminTimeZone,
      userInfo.time_zone
    );
  };
  const get_start_date_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "YYYY-MM-DD HH:mm",
      adminTimeZone,
      userInfo.time_zone
    );
  };
  const get_start_time_for_popup = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "hh:mm A",
      adminTimeZone,
      userInfo.time_zone
    );
  };
  const get_start_end_time = (date) => {
    return TIME_ZONE_CONVERSION(
      date,
      "HH:mm",
      adminTimeZone,
      userInfo.time_zone
    );
  };

  const getEventListing = async () => {
    if (selectedTool !== "month") {
      setIsLoading(true);
    }
    let postData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
      default_calendar_value: selectedTool,
    };
    const result = await new_event_list_api(postData);
    if (result.code === 200) {
      let all_events = [];
      // console.log(result, "Fdsfkjaslk");
      moment.tz.setDefault(result.time_zone);
      if (result.event.length > 0) {
        all_events = result.event
          .filter(
            (filter_event) =>
              //if delegate sets inactive an iteartaion then remove from this list
              filter_event.action_by == "member_user" ||
              filter_event.status == true
          )
          .map((event) => {
            return {
              ...event,
              title: event.title,
              color: event.color,
              event_color: event.color,
              event_title: event.title,
              recurring_type: event.recurring_type,
              weekday: event.weekday,
              status: event.status,
              date: get_converted_date(event.start_date_time),
              start_date: moment(event.start_date_time).format("YYYY-MM-DD"),
              end_date: moment(event.end_date_time).format("YYYY-MM-DD"),
              start: get_start_date_end_time(event.start_date_time),
              end: get_start_date_end_time(event.end_date_time),
              start_time: get_start_end_time(event.start_date_time),
              start_time_for_popup: get_start_time_for_popup(
                event.start_date_time
              ),
              end_time: get_start_end_time(event.end_date_time),
            };
          });
      }
      setEvents(all_events);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  const TOOL_BUTTONS = [
    {
      text: "month",
    },
    {
      text: "week",
    },
    {
      text: "day",
    },
  ];
  const getCenteredText = () => {
    let text = moment(new Date()).format("MMM YYYY");
    if (selectedTool == "month") {
      // text = moment(currentDate.start_date).add(1, "month").format("MMM YYYY");

      if (calendarRef?.current) {
        const calendarApi = calendarRef?.current.getApi();
        const currentMonth = calendarApi.getDate().getMonth(); // Get the current month (0-based index)
        const currentYear = calendarApi.getDate().getFullYear(); // Get the current year

        text = moment().month(currentMonth).format("MMM") + " " + currentYear;
      }
      // console.log(currentDate.start_date, "dfkjsdla");
    } else if (selectedTool == "week") {
      let month = moment(currentDate.start_date).format("MMM");
      let start_date = moment(currentDate.start_date).format("DD");
      let end_date = moment(currentDate.end_date).format("DD");
      let year = moment(currentDate.start_date).format("YYYY");
      text = `${month} ${start_date} - ${end_date} ${year}`;
    } else {
      text = moment(currentDate.start_date).format("MMM DD, YYYY");
    }
    return text;
  };
  const hanldeChangeDates = (value) => {
    let start_date = currentDate.start_date;
    let end_date = currentDate.end_date;
    let new_object = {};
    if (value == "next") {
      new_object = {
        start_date: moment(start_date)
          .add(1, selectedTool)
          .startOf(selectedTool)
          .toDate(),
        end_date: moment(end_date)
          .add(1, selectedTool)
          .endOf(selectedTool)
          .toDate(),
      };
    } else {
      new_object = {
        start_date: moment(start_date)
          .subtract(1, selectedTool)
          .startOf(selectedTool)
          .toDate(),
        end_date: moment(end_date)
          .subtract(1, selectedTool)
          .endOf(selectedTool)
          .toDate(),
      };
    }

    setCurrentDate(new_object);
  };
  const handleChangeNextPrev = (value) => {
    if (selectedTool == "month") {
      let classToClick = "fc-next-button";
      if (value == "prev") {
        classToClick = "fc-prev-button";
      }
      let click_element = document.getElementsByClassName(classToClick)[0];
      click_element.click();
    }

    hanldeChangeDates(value);
  };
  const handleChangeTools = (tool) => {
    setUserInfo((old) => {
      return {
        ...old,
        default_calendar_value: tool,
      };
    });
    let dates = get_dates(tool);
    setCurrentDate(dates);
    setSelectedTool(tool);
  };
  const handleClickDate = (event) => {
    setSelectedTool("day");

    setCurrentDate({
      start_date: event.dateStr,
      end_date: event.dateStr,
    });
  };

  useEffect(() => {
    getEventListing();
  }, [currentDate]);
  console.log(events, "finalEvents");

  console.log(events, "events");
  // if (isLoading) {
  //   return <CalenderSkeleton />;
  // }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {contentSettingData && contentSettingData.calender_description ? (
            <div
              className="dashboard_description"
              dangerouslySetInnerHTML={{
                __html: contentSettingData.calender_description,
              }}
            ></div>
          ) : (
            <h2 className="quotes-heading">Calendar</h2>
          )}
        </div>
        <div className="col-12">
          <button
            className="small-contained-button float-end mt-1 mb-4"
            onClick={handleOpenDrawer}
          >
            Add Event
          </button>
        </div>
        {isLoading && <CalenderSkeleton />}
        {!isLoading && (
          <div className="col-6 order-2 col-md-4 full-calendar-toolbar">
            <div class="btn-group toollbar-group-buttons">
              <button
                className={`small-contained-button`}
                onClick={() => {
                  handleChangeNextPrev("prev");
                }}
              >
                <ArrowBackIosIcon />
              </button>
              <button
                className={`small-contained-button`}
                onClick={() => {
                  handleChangeNextPrev("next");
                }}
              >
                <ArrowForwardIosIcon />
              </button>
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="col-12 col-md-4 order-1 order-md-2 mb-4 mb-md-0 text-center full-calendar-toolbar">
            <h2>{getCenteredText()}</h2>
          </div>
        )}
        {!isLoading && (
          <div className="col-6 col-md-4 order-3 text-center text-md-end">
            <div class="btn-group toollbar-group-buttons">
              {TOOL_BUTTONS.map((button) => {
                return (
                  <button
                    className={`small-contained-button ${
                      button.text == selectedTool
                        ? "selected-button"
                        : "not-selected-button"
                    }`}
                    onClick={() => {
                      handleChangeTools(button.text);
                    }}
                  >
                    {button.text}
                  </button>
                );
              })}
            </div>
          </div>
        )}
        {!isLoading && (
          <div className="col-md-12 full-calendar full-calendar-new-flow mt-3 order-4">
            {selectedTool == "month" ? (
              <FullCalendar
                ref={calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: "today prev,next",
                  center: "title",
                  right: "dayGridMonth,timeGridWeek,timeGridDay",
                }}
                className="fc-day-sun fc-toolbar-chunk fc eventimage fc-col-header-cell-cushion fc-today-button fc-right fc-prev-button fc-right fc-next-button fc-dayGridMonth-button fc-timeGridWeek-button fc-timeGridDay-button fc-daygrid-day-number fc-daygrid-day-top"
                eventContent={renderEventContent}
                events={events}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={window.screen.width > 768 ? 2 : 1}
                weekends={true}
                fixedWeekCount={false}
                eventOverlap={false}
                moreLinkContent={function (args) {
                  return "+" + args.num;
                }}
                dateClick={function (args) {
                  handleClickDate(args);
                }}
                dayPopoverFormat={{
                  month: "long",
                  year: "numeric",
                  day: "numeric",
                  weekday: "long",
                }}
                // moreLinkClick={function (args) {
                //   handleClickDate(args);
                // }}
              />
            ) : selectedTool == "week" ? (
              <WeekCalendar
                events={events}
                handleClick={eventDetail}
                currentDate={currentDate}
              />
            ) : (
              <DayCalendar
                events={events}
                handleClick={eventDetail}
                currentDate={currentDate}
              />
            )}
          </div>
        )}
      </div>
      <DetailPopUpModel
        open={popupState}
        setOpen={setPopupState}
        handleAgreeDelete={handleAgreeDelete}
        handleEdit={handleUpdate}
        title={"Event Detail"}
        eventDetailData={eventDetailData}
        componentToPassDown={
          <EventDetailNew
            setPopupState={setPopupState}
            setIsLoading={setIsLoading}
            eventDetailData={eventDetailData}
            setEventDetailData={setEventDetailData}
            dataList={getEventListing}
            onCloseDrawer={handleCloseEditDrawer}
            setEditDrawerState={setEditDrawerState}
          />
        }
      />
      <PerformActionOn
        open={openDelete}
        setOpen={setOpenDelete}
        title={"Delete recurring event?"}
        handleAgree={handleDelete}
      />
      <CustomDrawer
        isOpenDrawer={editDrawerState}
        onOpenDrawer={handleOpenEditDrawer}
        onCloseDrawer={handleCloseEditDrawer}
        pageTitle="Event Detail"
        componentToPassDown={
          <EventDetail
            setIsLoading={setIsLoading}
            eventDetailData={eventDetailData}
            onCloseDrawer={handleCloseEditDrawer}
          />
        }
      />

      {/* <CustomDrawer
        isOpenDrawer={drawerState}
        onOpenDrawer={handleOpenDrawer}
        onCloseDrawer={handleCloseDrawer}
        pageTitle="New Event"
        componentToPassDown={
          <AddEvent
            dataList={getEventListing}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      /> */}
      <FullPagePopup
        open={editDrawerState}
        setOpen={setEditDrawerState}
        componentToPassDown={
          <EditEvent
            editValues={eventDetailData}
            dataList={getEventListing}
            handleCloseDrawer={handleCloseEditDrawer}
          />
        }
      />
      <FullPagePopup
        open={drawerState}
        setOpen={setDrawerState}
        componentToPassDown={
          <AddEvent
            dataList={getEventListing}
            onCloseDrawer={handleCloseDrawer}
          />
        }
      />
    </div>
  );
}

export default Calender;
