import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";

import {
  Container,
  IconButton,
  CircularProgress,
  Menu,
  MenuItem,
  Chip,
} from "@mui/material";
import { s3baseUrl } from "src/config/config";

import { useSnackbar } from "notistack";
import { makeStyles } from "@mui/styles";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { color } from "@mui/system";
import FileViewer from "react-file-viewer";
import { bookDetail } from "src/DAL/Books/Books";
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));
const ITEM_HEIGHT = 48;

function BookDetail(props) {
  const classes = useStyles();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [isLoading, setIsLoading] = useState(false);
  const [programmeDetailInfo, setProgrammeDetailInfo] = useState();
  const [extensionUrl, setExtensionUrl] = useState("");
  const canvasRef = useRef(null);

  const [documentUri, setDocumentUri] = useState(null);

  const [isLoaded, setIsLoaded] = useState(false);
  const [fileUrl, setFileUrl] = useState("");

  useEffect(() => {
    if (programmeDetailInfo?.external_url) {
      const fileNameUrl = programmeDetailInfo.external_url.split("/").pop();
      const ext = fileNameUrl?.split(".").pop()?.toLowerCase();

      setFileUrl(programmeDetailInfo.external_url);
      setExtensionUrl(ext);
    }
  }, [programmeDetailInfo]);

  const getDocumentUrl = (fileUrl) => {
    const extension = fileUrl.split(".").pop();
    if (["pdf"].includes(extension)) {
      return s3baseUrl + fileUrl;
    } else if (["doc", "docx", "xls", "xlsx", "csv"].includes(extension)) {
      return s3baseUrl + fileUrl;
    }
    return s3baseUrl + fileUrl;
  };
  console.log(programmeDetailInfo?.external_url, "exterexterextet");
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };
  const fileName = programmeDetailInfo?.document_file?.split("/").pop();
  let extension = fileName?.split(".").pop().toLowerCase();
  const fileExtensionsMap = {
    doc: "docx",
    xls: "xlsx",
    ppt: "pptx",
  };
  extension = fileExtensionsMap[extension] || extension;
  console.log(extension, "extensionextensionextension");

  const fileNameUrl = programmeDetailInfo?.external_url?.split("/").pop();
  console.log(extensionUrl, "extensionUrlextensionUrlextensionUrl");

  const handleNavigateEdit = () => {
    navigate(`/books/${state?.detailValues._id}/edit-book`, {
      state: programmeDetailInfo,
    });
  };

  const handleDetail = async () => {
    setIsLoading(true);
    let result = await bookDetail(state?.detailValues._id);
    if (result.code === 200) {
      setProgrammeDetailInfo(result.data);
      setIsLoading(false);
      setIsLoaded(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  const filePathViewr = programmeDetailInfo?.external_url;
  console.log(filePathViewr, "filePathViewrfilePathViewrfilePathViewr");
  useEffect(() => {
    handleDetail();
  }, []);

  // useEffect(() => {
  //   setIsLoaded(false);
  // }, [programmeDetailInfo]);

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  console.log(
    programmeDetailInfo,
    "programmeDetailInfoprogrammeDetailInfoprogrammeDetailInfo"
  );
  let breadCrumbMenu = [
    { title: "Books", navigation: `/books`, active: false },
    { title: programmeDetailInfo?.title, navigation: null, active: true },
  ];
  const randomParam = Math.random();

  return (
    <>
      <div className="container">
        <div className="row mobile-margin display-flex">
          <div className="col-12">
            <div className="d-flex">
              <IconButton
                className="back-screen-button"
                onClick={() => navigate("/books")}
              >
                <ArrowBackIcon />
              </IconButton>
              <h2 className="card-title ">{programmeDetailInfo?.title}</h2>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-2">
              <img
                width={120}
                height="auto"
                src={s3baseUrl + programmeDetailInfo?.image}
                alt="Group Image"
                className="img-fluid"
              />
            </div>
            <div className="col-9">
              <div>
                <h5 className="card-title">{programmeDetailInfo?.title}</h5>
                <div
                  dangerouslySetInnerHTML={{
                    __html: programmeDetailInfo?.detailed_description,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {programmeDetailInfo?.tags?.length > 0 && (
            <div className="tags-section">
              <div
                className="tags d-flex flex-wrap"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                <p
                  className="mt-2"
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    color: "white",
                    marginRight: "8px",
                  }}
                >
                  Book Tags:
                </p>
                {programmeDetailInfo.tags.map((tag) => (
                  <Chip
                    key={tag._id}
                    label={tag.name}
                    style={{
                      margin: "4px",
                      color: "black",
                      backgroundColor: "white",
                      fontWeight: "bold",
                    }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="books-detail-document">
          {programmeDetailInfo && programmeDetailInfo.type === "document_file" && (
            <div className="other-resource" style={{ overflow: "hidden" }}>
              {["pdf", "csv"].includes(
                programmeDetailInfo.document_file.split(".").pop().toLowerCase()
              ) ? (
                <FileViewer
                  fileType={extension}
                  filePath={getDocumentUrl(programmeDetailInfo.document_file)}
                />
              ) : (
                <DocViewer
                  documents={[
                    {
                      uri: getDocumentUrl(programmeDetailInfo.document_file),
                    },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                />
              )}
            </div>
          )}
          {programmeDetailInfo?.type === "embed_code" &&
            programmeDetailInfo?.embed_code && (
              <iframe
                className="other-resource"
                width="100%"
                height="600px"
                frameBorder="0"
                preload="auto"
                srcDoc={programmeDetailInfo.embed_code}
                title="Embedded Document"
              ></iframe>
            )}
        </div>
        <div className="books-detail-document col-12">
          {programmeDetailInfo && programmeDetailInfo.type === "external_url" && (
            <>
              <iframe
                className="other-resource"
                width="100%"
                frameborder="0"
                preload="auto"
                onError={() => console.log("Error loading document")}
                src={`https://docs.google.com/gview?url=${filePathViewr}&embedded=true&random=${randomParam}`}
              ></iframe>
            </>
          )}
        </div>
        <div className="books-detail-document">
          {programmeDetailInfo?.type == "embed_code" && (
            <>
              <iframe
                className="other-resource"
                width="100%"
                frameborder="0"
                preload="auto"
                onError={() => console.log("Error loading document")}
                src={programmeDetailInfo.external_url}
              ></iframe>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default BookDetail;
