// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import DashboardCircle from "src/components/_dashboard/app/DashboardCircle";
import { useContext, useState } from "react";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import { project_name } from "src/config/config";
import DashboardCard from "src/components/_dashboard/app/DashboardCard";
import { _get_is_seen_video_localStorage } from "src/DAL/localStorage/localStorage";
import Disclaimer from "src/components/Disclaimer";
import { useEffect } from "react";
import { ProjectInfoApi, memberDetail } from "src/DAL/Profile/Profile";
import ReactVideoPlayerDashboard from "src/components/ReactPlayers/ReactVideoPlayerDashboard";
import Feed from "./CommunityFeeds/Feed";
import { useNavigate } from "react-router-dom";
import MyPosts from "./CommunityFeeds/MyPosts";
import SyncIcon from "@mui/icons-material/Sync";
// ----------------------------------------------------------------------

export default function DashboardApp() {
  const {
    contentSettingData,
    dashboardSettingData,
    dashboardTestimonialData,
    dashboardEventData,
  } = useContentSetting();
  const navigate = useNavigate();
  const [openPasswordModal, setopenPasswordModal] = useState(false);
  const [disclaimerDetail, setDisclaimerDetail] = useState("");
  const [disclaimerHeading, setDisclaimerHeading] = useState("");
  const [disclaimerLabel, setDisclaimerLabel] = useState("");
  const [disclaimerButton, setDisclaimerButton] = useState("");
  const [disclaimerStatus, setDisclaimerStatus] = useState("");
  const [memberData, setMemberData] = useState({});
  const [myPosts, setMyPosts] = useState(false);
  const is_seen_video = _get_is_seen_video_localStorage();
  const handleMyPosts = () => {
    if (myPosts) {
      setMyPosts(false);
    } else {
      setMyPosts(true);
    }

    // navigate(`/community-area/my-posts`);
  };
  //Getting Project Info from API
  const getProjectInfo = async () => {
    const result = await ProjectInfoApi();
    if (result.code === 200) {
      setDisclaimerDetail(result.member_setting.disclaimer_text);
      setDisclaimerStatus(result.member_setting.is_disclaimer_show);
      setDisclaimerButton(result.member_setting.disclaimer_button_text);
      setDisclaimerLabel(result.member_setting.checkbox_label);
      setDisclaimerHeading(result.member_setting.disclaimer_heading);
      if (result.member_setting.is_disclaimer_show) {
        setopenPasswordModal(true);
      }
      getMemberData();
    }
  };
  const getMemberData = async () => {
    const result = await memberDetail();
    if (result.code === 200) {
      console.log(result, "resultresultresultresult");

      setMemberData(result.member);
    }
  };
  useEffect(() => {
    getProjectInfo();
  }, []);
  return (
    // <Page title="Dashboard ">
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 dashboard_description">
            {contentSettingData && contentSettingData.dashboard_description ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: contentSettingData.dashboard_description,
                }}
              ></div>
            ) : (
              <h2 className="dashboard-heading">
                {dashboardSettingData?.wellcome_text}
              </h2>
            )}
          </div>
        </div>
        <div className="row">
          {/* <div className="col-12 mt-5 mx-auto text-center">
          <DashboardCard data={dashboardSettingData} />
        </div> */}
          {(project_name == "DD" ||
            project_name == "BASIC_DEV" ||
            project_name == "PGI_DEV") && (
            <>
              <div className="col-sm-12 col-md-12 col-lg-12 ">
                <DashboardCircle data={dashboardSettingData} />
              </div>
            </>
          )}
          {
            dashboardSettingData?.video_url && (
              <span>
                {
                  is_seen_video === "No" && (
                    <div className="col-sm-12 col-md-12 col-lg-12">
                      <ReactVideoPlayerDashboard
                        url={dashboardSettingData?.video_url}
                        setting={dashboardSettingData}
                      />
                    </div>
                  )
                  // : (
                  //   <div
                  //     dangerouslySetInnerHTML={{
                  //       __html: dashboardSettingData?.welcome_image,
                  //     }}></div>
                  // )
                }
              </span>
            )
            // : (
            //   <div
            //     dangerouslySetInnerHTML={{
            //       __html: dashboardSettingData?.welcome_image,
            //     }}></div>
            // )
          }
          <div
            dangerouslySetInnerHTML={{
              __html: dashboardSettingData?.welcome_image,
            }}
          ></div>
        </div>
        {memberData.disclaimer_status == false && (
          <Disclaimer
            openPasswordModal={openPasswordModal}
            setopenPasswordModal={setopenPasswordModal}
            disclaimerDetail={disclaimerDetail}
            disclaimerStatus={disclaimerStatus}
            disclaimerButton={disclaimerButton}
            disclaimerHeading={disclaimerHeading}
            disclaimerLabel={disclaimerLabel}
          />
        )}
        <div className="row d-none d-lg-flex">
          <div className="col-lg-7 col-sm-12 text-end">
            <h2>{myPosts ? "My Posts" : "Community Area"}</h2>
          </div>
          {myPosts ? (
            <div className="col-lg-5 col-sm-12 text-end">
              <button
                className="small-contained-button"
                onClick={handleMyPosts}
              >
                {" "}
                <SyncIcon
                  sx={{
                    width: "25px",
                    height: "25px",
                    marginRight: "3px",
                  }}
                />
                Community Area
              </button>
            </div>
          ) : (
            <div className="col-lg-5 col-sm-12 text-end">
              <button
                className="small-contained-button"
                onClick={handleMyPosts}
              >
                {" "}
                <SyncIcon
                  sx={{
                    width: "25px",
                    height: "25px",
                    marginRight: "3px",
                  }}
                />
                My Posts
              </button>
            </div>
          )}
        </div>
      </div>

      <div className="mt-4">
        {myPosts ? (
          <MyPosts myPostsPage={myPosts} handleMyPosts={handleMyPosts} />
        ) : (
          <Feed myPostsPage={myPosts} handleMyPosts={handleMyPosts} />
        )}
      </div>
    </>
    // </Page>
  );
}
