import { useCallback, useEffect, useRef, useState } from "react";
import { Chip, CircularProgress, TextField } from "@mui/material";
import { s3baseUrl } from "src/config/config";
import { htmlDecode } from "src/utils/convertHtml";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import RecordNotFound from "src/components/GeneralComponents/RecordNotFound";
import { activeTagsApi, BookListApi } from "src/DAL/Books/Books";
import BooksFilter from "./BooksFilter";
import CustomDrawer from "src/components/GeneralComponents/CustomDrawer";
import Badge from "@mui/material/Badge";
import Skeleton from "react-loading-skeleton";

// ----------------------------------------------------------------------
const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  notchedOutline: {
    borderColor: "#fff !important",
    height: "55px",
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (user) => user.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
export default function BooksList() {
  const classes = useStyles();
  const EMPTY_FILTER = {
    start_date: new Date(),
    end_date: new Date(),
    sale_page: null,
    payment_plan: null,
    expiry_in: "",
    expired: null,
    filter: "all",
    filter: "all",
    expiry_name: null,
    search_text: "",
    tags: [],
  };
  const observer = useRef();

  const [filterCount, setFilterCount] = useState(0);
  const [filterState, setFilterState] = useState(EMPTY_FILTER);
  const [filterStateUpdated, setFilterStateUpdated] = useState(EMPTY_FILTER);
  const [isWaiting, setIsWaiting] = useState(true);

  const [pageCount, setPageCount] = useState(1);
  const [groupsName, setGroupsName] = useState([]);
  const [selectedPage, setSelectedPage] = useState(null);
  const [tagsList, setTagsList] = useState([]);

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openFilter, setOpenFilter] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [isLoadingSection, setIsLoadingSection] = useState(true);

  const [orderBy, setOrderBy] = useState("number");
  const [order, setOrder] = useState("asc");
  const [booksList, setBooksList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filterDrawerState, setFilterDrawerState] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [cardSearchText, setCardSearchText] = useState("");

  const [searchText, setSearchText] = useState("");
  const [loadMorePath, setLoadMorePath] = useState("");
  const load_more_path = "api/books_library/client_list?page=0&limit=15";

  const [filterName, setFilterName] = useState("");
  const getTagsList = async () => {
    try {
      const result = await activeTagsApi();
      if (result.code === 200) {
        const tags = result.activeTags.map((tag) => ({
          chip_label: tag.name,
          chip_value: tag._id,
          ...tag,
        }));
        setTagsList(tags);
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar("Error fetching tags", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };
  // value is filterstate
  // loading is loadmorepath
  const BookList = async (value, path, loading) => {
    console.log(value, "Filter value (clear or selected)");
    console.log(groupsName, "Selected tags (groupsName)");
    if (!loading) {
      setIsLoading(true);
    }

    setIsLoadingMore(true);

    let postData = {
      search: searchText || cardSearchText,
      tags: value,
    };

    // let data = { page, rowsPerPage, postData, loadMorePath };
    // console.log(data, "datadatadatadatadatadatadatadatadata");
    // let currentPath = loadMorePath
    //   ? loadMorePath
    //   : `api/books_library/client_list?page=${page}&limit=${rowsPerPage}`;

    const result = await BookListApi(postData, path);
    console.log(result, "API response");

    if (result.code == 200) {
      setIsWaiting(false);
      setTotalCount(result.total);
      setTotalPages(result.total_pages);
      setLoadMorePath(result.load_more_url);

      if (!loading) {
        setPageNumber(1);
        setBooksList(result.books);
      } else {
        const updatedBooksList = booksList.concat(result.books);
        setBooksList(updatedBooksList);
        setPageNumber((prev) => prev + 1);
      }
      setIsLoadingMore(false);
    } else {
      setIsWaiting(false);
      setIsLoadingMore(false);
      setIsLoadingSection(false);
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };
  console.log("called the function..................");
  const searchFunction = () => {
    setPage(0);
    setPageCount(1);
    filterState.search_text = searchText;
    setFilterCount(groupsName.length);
    BookList(groupsName, load_more_path);
    handleCloseFilterDrawer();
    localStorage.setItem("book_filter_data", JSON.stringify(filterState));
  };

  const handleClearFilter = () => {
    setGroupsName([]);
    setPage(0);
    setPageCount(1);
    setSearchText("");
    setFilterCount(0);
    setFilterState(EMPTY_FILTER);
    BookList([], load_more_path);
    handleCloseFilterDrawer();
  };
  const loadMoreData = () => {
    setIsLoadingMore(true);
    BookList(groupsName, loadMorePath, true);
  };
  const lastBookElementRef = useCallback(
    (node) => {
      if (isLoadingMore) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && totalPages > pageNumber) {
          loadMoreData();
        }
      });
      if (node) observer.current.observe(node);
    },
    [isLoadingMore, totalPages, pageNumber, loadMorePath, observer]
  );

  const handleNavigateDetail = (value) => {
    navigate(`/books/book-detail/${value._id}`, {
      state: { detailValues: value },
    });
  };
  const handleOpenFilterDrawer = () => {
    setFilterDrawerState(true);
    setFilterState(filterStateUpdated);
  };
  const handleCloseFilterDrawer = () => {
    setFilterDrawerState(false);
  };

  const filteredbooksListe = applySortFilter(
    booksList,
    getComparator(order, orderBy),
    filterName
  );

  useEffect(() => {
    BookList(groupsName, load_more_path);
    getTagsList();
  }, []);
  const searchFunctionTwo = (e) => {
    console.log(e, "eeeeeeeeeeeeeeeeeeeeeeeeee");

    e.preventDefault();
    setBooksList([]);

    BookList(groupsName, load_more_path);
  };

  // if (isWaiting) {
  //   return (
  //     <div className="container mt-2">
  //       <div className="row">
  //         {Array.from({ length: 6 }).map((_, index) => (
  //           <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
  //             <div className="card mt-1 pods-cards-shadow cursor h-100">
  //               <Skeleton
  //                 height={200}
  //                 className="card-img-top pods-image"
  //                 baseColor="#dddde8"
  //                 highlightColor="#ffffff"
  //               />
  //               <div className="card-body">
  //                 <Skeleton
  //                   height={30}
  //                   width="80%"
  //                   style={{ marginBottom: 10 }}
  //                   className="h2-heading"
  //                   baseColor="#dddde8"
  //                   highlightColor="#9F00FF"
  //                 />
  //                 <Skeleton
  //                   height={20}
  //                   width="80%"
  //                   style={{ marginBottom: 10 }}
  //                   className="h2-heading"
  //                   baseColor="#dddde8"
  //                   highlightColor="#cf7fff"
  //                 />
  //                 <Skeleton
  //                   height={20}
  //                   width="90%"
  //                   className="programme-card-desc mb-3"
  //                   baseColor="#dddde8"
  //                   highlightColor="#ffffff"
  //                 />
  //                 <Skeleton
  //                   height={20}
  //                   width="60%"
  //                   baseColor="#dddde8"
  //                   highlightColor="#ffffff"
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // }
  const isRecordNotFound = filteredbooksListe.length === 0;
  return (
    <div className="container mt-2">
      <div className="row">
        <div className="col-lg-6 col-sm-12 d-flex">
          <h2>Books </h2>
        </div>
      </div>
      <div className="row justify-content-end">
        <div className="col-md-12 justify-content-end text-end">
          <TextField
            fullWidth
            label="Search"
            InputProps={{
              classes: {
                notchedOutline: classes.notchedOutline,
              },
            }}
            sx={{ marginRight: 2, width: 240 }}
            className="custom-search-field"
            value={cardSearchText}
            onChange={(e) => setCardSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") searchFunctionTwo(e);
            }}
          />
          <Badge
            badgeContent={filterCount}
            color="error"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            overlap="circular"
          >
            <button
              className="small-contained-button"
              onClick={handleOpenFilterDrawer}
            >
              <FilterListIcon />
              Filters
            </button>
          </Badge>{" "}
        </div>
      </div>
      {isWaiting && (
        <div className="container mt-2">
          <div className="row">
            {Array.from({ length: 6 }).map((_, index) => (
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                <div className="card mt-1 pods-cards-shadow cursor h-100">
                  <Skeleton
                    height={200}
                    className="card-img-top pods-image"
                    baseColor="#dddde8"
                    highlightColor="#ffffff"
                  />
                  <div className="card-body">
                    <Skeleton
                      height={30}
                      width="80%"
                      style={{ marginBottom: 10 }}
                      className="h2-heading"
                      baseColor="#dddde8"
                      highlightColor="#9F00FF"
                    />
                    <Skeleton
                      height={20}
                      width="80%"
                      style={{ marginBottom: 10 }}
                      className="h2-heading"
                      baseColor="#dddde8"
                      highlightColor="#cf7fff"
                    />
                    <Skeleton
                      height={20}
                      width="90%"
                      className="programme-card-desc mb-3"
                      baseColor="#dddde8"
                      highlightColor="#ffffff"
                    />
                    <Skeleton
                      height={20}
                      width="60%"
                      baseColor="#dddde8"
                      highlightColor="#ffffff"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="row">
        {filteredbooksListe.map((value, index) => {
          return (
            <div className="col-lg-4 col-md-4 col-sm-12 mb-4 mt-1" key={index}>
              <div className="card mt-1 pods-cards-shadow cursor h-100">
                {!value?.status && (
                  <div
                    className="inactive-badge"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      backgroundColor: "red",
                      color: "white",
                      padding: "5px 10px",
                      borderRadius: "16px 0 10px 0",
                      zIndex: 1,
                    }}
                  >
                    Inactive
                  </div>
                )}
                <img
                  src={s3baseUrl + value?.image}
                  className="card-img-top pods-image"
                  alt="booksListme"
                  onClick={() => handleNavigateDetail(value)}
                />
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <h3
                        className="h2-heading"
                        onClick={() => handleNavigateDetail(value)}
                      >
                        {htmlDecode(value.title)}
                      </h3>
                    </div>
                    <div className="tags-chip mb-1">
                      {value.tags &&
                        value.tags?.map((tag, index) => (
                          <Chip
                            sx={{
                              color: "white",
                              backgroundColor: "#cf7fff",
                              marginLeft: "4px",
                              borderRadius: "7px",
                              fontSize: "0.75rem",
                              marginTop: "5px",
                            }}
                            key={index}
                            label={tag.title}
                            size="small"
                            variant="contained"
                          />
                        ))}
                    </div>

                    <div className="col-2 menu-option"></div>
                  </div>
                  <p className="booksListme-card-desc">
                    {htmlDecode(value.short_description)}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
        {filteredbooksListe.length > 0 && loadMorePath && (
          <div className="col-12 text-center favourite-buttton-box">
            <button
              // ref={lastBookElementRef}
              className="small-contained-button mt-3"
              onClick={loadMoreData}
              id="load-more-feed"
            >
              {isLoadingMore ? "Loading..." : "Load More"}
            </button>
          </div>
        )}

        {isRecordNotFound && <RecordNotFound title="Books Not Found" />}
      </div>
      <CustomDrawer
        isOpenDrawer={filterDrawerState}
        onOpenDrawer={handleOpenFilterDrawer}
        onCloseDrawer={handleCloseFilterDrawer}
        pageTitle="Filters"
        componentToPassDown={
          <BooksFilter
            searchSubmitFilter={searchFunction}
            handleClearFilter={handleClearFilter}
            setGroupsName={setGroupsName}
            groupsName={groupsName}
            tagsList={tagsList}
          />
        }
      />
    </div>
  );
}
