import { useFormik } from "formik";
import { useState } from "react";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
// material
import { CircularProgress, Container, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
// components
import {
  ProgrammesCard,
  ProgrammesList,
  RecordNotFound,
} from "../../components/_dashboard/programmes";
//
import { programmesListing } from "../../DAL/Programmes/Programmes";
import { useContentSetting } from "src/Hooks/ContentContext/ContentSettingState";
import ProgrammesCardFirstIndex from "src/components/_dashboard/programmes/ProgrammesCardFirstIndex";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Ensure this CSS is imported

// ----------------------------------------------------------------------

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

export default function Programmes() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [programmesData, setProgrammesData] = useState([]);

  // const [programmesData, setProgrammesData] = useState([]);
  const [firstProgrammeData, setFirstProgrammeData] = useState({});
  const { contentSettingData } = useContentSetting();
  const getProgrammesList = async () => {
    const result = await programmesListing();
    console.log(result, "resultresult rec");
    if (result.code === 200) {
      // if (result.program.length > 0) {
      //   setFirstProgrammeData(result.program[0]);
      //   delete result.program[0];
      // }

      setProgrammesData(result.program);
      setIsLoading(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getProgrammesList();
  }, []);

  // if (isLoading) {
  //   return (
  //     <div className="container mt-2">
  //       <div className="row">
  //         {Array.from({ length: 6 }).map((_, index) => (
  //           <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
  //             <div className="card mt-1 pods-cards-shadow cursor h-100">
  //               <Skeleton
  //                 height={200}
  //                 className="card-img-top pods-image"
  //                 baseColor="#dddde8"
  //                 highlightColor="#ffffff"
  //               />
  //               <div className="card-body">
  //                 <Skeleton
  //                   height={30}
  //                   width="80%"
  //                   style={{ marginBottom: 10 }}
  //                   className="h2-heading"
  //                   baseColor="#dddde8"
  //                   highlightColor="#9F00FF"
  //                 />
  //                 <Skeleton
  //                   height={20}
  //                   width="90%"
  //                   className="programme-card-desc mb-3"
  //                   baseColor="#dddde8"
  //                   highlightColor="#ffffff"
  //                 />
  //                 <Skeleton
  //                   height={20}
  //                   width="60%"
  //                   baseColor="#dddde8"
  //                   highlightColor="#ffffff"
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="container">
      {contentSettingData && contentSettingData.programs_description ? (
        <div
          className="dashboard_description"
          dangerouslySetInnerHTML={{
            __html: contentSettingData.programs_description,
          }}
        ></div>
      ) : (
        <h2>Programmes</h2>
      )}
      {isLoading && (
        <div className="container mt-2">
          <div className="row">
            {Array.from({ length: 6 }).map((_, index) => (
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4" key={index}>
                <div className="card mt-1 pods-cards-shadow cursor h-100">
                  <Skeleton
                    height={200}
                    className="card-img-top pods-image"
                    baseColor="#dddde8"
                    highlightColor="#ffffff"
                  />
                  <div className="card-body">
                    <Skeleton
                      height={30}
                      width="80%"
                      style={{ marginBottom: 10 }}
                      className="h2-heading"
                      baseColor="#dddde8"
                      highlightColor="#9F00FF"
                    />
                    <Skeleton
                      height={20}
                      width="90%"
                      className="programme-card-desc mb-3"
                      baseColor="#dddde8"
                      highlightColor="#ffffff"
                    />
                    <Skeleton
                      height={20}
                      width="60%"
                      baseColor="#dddde8"
                      highlightColor="#ffffff"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {programmesData.length > 0 ? (
        <>
          {/* <div className="row">
            <div className="col-12">
              <ProgrammesCardFirstIndex programm={firstProgrammeData} />
            </div>
          </div> */}
          <ProgrammesList programmes={programmesData} />
        </>
      ) : (
        <>
          <RecordNotFound title="Programmes" />
        </>
      )}
    </div>
  );
}
